import * as React from "react";

import IndexLayout from "../layouts";

import { useEffect } from "react";
import { navigate } from "gatsby";

const ResearchPage = () => {
  useEffect(() => {
    navigate("https://blog.skodel.com/");
  }, []);

  return <IndexLayout></IndexLayout>;
};

export default ResearchPage;
